
body{
    background: rgba(79, 79, 240, 0.74);
}


.container{
    position: absolute;
    width: 60%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    background: rgb(206, 203, 203);

}

.title{
    position: relative;
    background-color: rgb(47, 47, 47);
    color: rgb(255, 255, 255);
    padding: 20px;
    font-size: 1.8em;
    border-radius: 5px;
}




.events{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: bold;
    margin-bottom: 5px;
    color: rgb(255, 255, 255);
    font-size: 50px;
    text-align: center;
    margin-top: auto;
    z-index: 3;
}

.event_container{

    position: relative;
    margin-top: 5px;
    padding: 15px;
    box-sizing: border-box;

}

.eventBtnContainer{
    padding-top: 40px;
    padding-bottom: 40px;

    display: flex;
    justify-content: center;


}

.eventBtn{
    text-align: center;
    cursor: pointer;
    font-size:24px;


    padding:20px 60px;
    outline: none;
    background-color: rgb(47, 47, 47);
    border: none;
    border-radius:5px;
    box-shadow: 0 9px #95a5a6;
    color: white;
    width: 400px;
    margin: auto;
    font-size: 50px;
}

.eventBtn:hover {
    background-color: white;
    color: rgb(47, 47, 47);
}

.eventBtn:active{
    background-color: rgb(255, 255, 255);
    box-shadow: 0 5px #000000;
    color: rgb(47, 47, 47);
    transform: translateY(4px);
}


.score{
    font-size: 30px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
}


.scoreCont{
    background: rgba(32, 32, 32, 0.596);
    border-radius: 8px;
}


.eventImg{
    width: 100%;
    height: 300px;
    filter:brightness(30%)contrast(80%)

}

.eventTop{
    position: relative;
}

.eventBot{
    position: relative;
}

.eventBot events{
    margin-top: 30px;
}
.eventText{
    font-weight: bold;
    margin-bottom: 5px;
    color: rgb(47, 47, 47);
    font-size: 50px;
    text-align: center;
    margin-top: 0px;
}